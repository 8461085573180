<template>
    <div>
        <div class="menu">
            <!-- MAP TAB (MOBILE ONLY) -->
            <div
                :class="{
                    'collapsible-title': true,
                    'is-active': page == 'map'
                }"
                @click="page = 'map'"
                v-if="!sharedView && ($mq == 'xs' || $mq == 'sm')"
            >
                <i class="fa-solid fa-map"></i>
            </div>
            <!-- VEHICLE INFO TAB -->
            <div
                :class="{
                    'collapsible-title': true,
                    'is-active': page == 'truck'
                }"
                @click="page = 'truck'"
                v-if="!sharedView"
            >
                <i class="fa-solid fa-truck"></i>

                <!-- Warning badge -->
                <b-badge
                    variant="danger"
                    class="bg-danger"
                    v-if="vehicleAlertsList.length > 0 && (this.$mq == 'xs' || this.$mq == 'sm')"
                >
                    <i class="fa-solid fa-triangle-exclamation text-white"></i>
                    {{ vehicleAlertsList.length }}
                </b-badge>
            </div>
            <!-- DRIVER INFO TAB -->
            <div
                :class="{
                    'collapsible-title': true,
                    'is-active': page == 'driver'
                }"
                @click="page = 'driver'"
                v-if="!sharedView"
            >
                <i class="fa fa-user"></i>
            </div>
        </div>
        <div
            class="content custom-scrollbar"
            :class="{
                'clear-content': sharedView,
                'first-tab-active':
                    (($mq == 'xs' || $mq == 'sm') && page == 'map') ||
                    ($mq != 'xs' && $mq != 'sm' && page == 'truck'),
                'center-tab-active': $mq != 'xs' && $mq != 'sm' && page == 'driver'
            }"
        >
            <!-- MAP CONTENT (MOBILE ONLY)-->
            <span v-show="page == 'map'" v-if="!sharedView && ($mq == 'xs' || $mq == 'sm')">
                <activity-wrapper :truck="truck" class="activity-wrapper" />
            </span>

            <!-- VEHICLE INFO CONTENT -->
            <span v-show="page == 'truck'">
                <div
                    class="vehicle-alerts bg-dark-blue mt-3 mb-4 p-2 rounded"
                    v-if="vehicleAlertsList.length > 0 && ($mq == 'xs' || $mq == 'sm')"
                >
                    <div
                        class="d-flex align-items-center gap-3"
                        v-for="(alert, index) in vehicleAlertsList"
                        show
                        :key="index"
                    >
                        <i class="text-white" :class="alert.icon"></i>
                        <span class="text-white text-smaller ms-2">
                            {{ __(alert.problem) }}
                        </span>
                    </div>
                </div>

                <small class="d-block mt-2 mb-1 ps-1 text-muted">{{
                    __('geofleet.detailspanel.bind_vehicle')
                }}</small>

                <golia-multiselect
                    v-model="localTags"
                    label="name"
                    track-by="id"
                    class="mb-2"
                    :emptyLabel="__('empty_search')"
                    :show-labels="false"
                    :outsideTagsSelected="true"
                    :customValueLabel="{
                        singular: __('geofleet.detailspanel.binded_tag'),
                        plural: __('geofleet.detailspanel.binded_tags')
                    }"
                    :tag-placeholder="__('geofleet.detailspanel.add_new_tag')"
                    :placeholder="__('geofleet.detailspanel.select_new_tag') + '...'"
                    :selectLabel="__('select')"
                    :selectedLabel="__('selected')"
                    :deselectLabel="__('deselect')"
                    :options="$store.state.geoFleet.companyTags"
                    :multiple="true"
                    :close-on-select="false"
                    :taggable="true"
                    :customSelectedLabel="{
                        singular: __('geofleet.detailspanel.binded_tag'),
                        plural: __('geofleet.detailspanel.binded_tags')
                    }"
                    :id="'groups-of-' + truck.id"
                    :refer="'groups-of' + truck.id"
                    @select="
                        (option, id) => {
                            handleTag(option, id, 'add')
                        }
                    "
                    @remove="
                        (option, id) => {
                            handleTag(option, id, 'remove')
                        }
                    "
                    @tag="addNewTag"
                >
                </golia-multiselect>

                <cloud-panel
                    v-if="truck.parameters && lock_command"
                    :ignore-null-value="true"
                    :data="commandsCloud"
                    :highlighted="true"
                    ref="commandsCloud"
                    @switchInputChange="switchInputChange"
                    @buttonClick="commandsClick"
                />

                <div v-if="is_truck_hooked(this.truck.plate)" class="cloud-vehicle-hooked">
                    <div class="d-flex align-items-center gap-3">
                        <i class="fa-regular fa-lg fa-link-horizontal"></i>
                        <span>
                            {{
                                get_type_vehicle_hooked(this.truck.plate) == 'truck'
                                    ? __('truck_hooked_text')
                                    : __('trailer_hooked_text')
                            }}
                            <b>{{ get_vehicle_hooked(this.truck.plate) }}</b>
                        </span>
                    </div>
                </div>

                <cloud-panel
                    v-if="truck.lastpoint"
                    :ignore-null-value="true"
                    :title="__('info')"
                    :data="infoCloud"
                />

                <cloud-panel
                    v-if="vehicleTemperatureProbes"
                    :ignore-null-value="true"
                    :title="__('temperature.title')"
                    :data="vehicleTemperatureProbes"
                />

                <!-- <cloud-panel
					v-if="dayFirstPoint"
					:ignore-null-value="true"
					:title="__('solar_day_report')"
					:data="dailyReport"
				/> -->

                <cloud-panel
                    :ignore-null-value="true"
                    :title="__('device')"
                    :data="deviceCloud"
                    v-if="
                        $can('use', 'telemetry') &&
                        deviceCloud &&
                        Object.entries(deviceCloud).length > 0 &&
                        truck?.lastpoint?.telemetry?.device_timestamp
                    "
                />

                <!-- <cloud-panel
                    v-if="
                        !sharedView && parametersCloud && Object.entries(parametersCloud).length > 0
                    "
                    :ignore-null-value="true"
                    :title="__('parameters')"
                    :data="parametersCloud"
                /> -->

                <transition name="common-fade">
                    <telemetry-cloud-panel
                        :truck="truck"
                        v-if="$can('use', 'telemetry') && !sharedView"
                        @telemetriesLoaded="telemetriesLoaded"
                    />
                </transition>
            </span>

            <!-- DRIVER INFO CONTENT -->
            <span v-show="page == 'driver'" v-if="!sharedView">
                <cloud-panel
                    v-if="truck.lastpoint.driver"
                    :ignore-null-value="true"
                    :title="__('driver') + ' 1'"
                    :data="driverInfo(truck.lastpoint.driver)"
                >
                    <div class="tiny-plafond">
                        <tiny-plafond :driver="truck.lastpoint.driver" />
                    </div>
                </cloud-panel>

                <cloud-panel
                    v-if="truck.lastpoint.codriver"
                    :ignore-null-value="true"
                    :title="__('driver') + ' 2'"
                    :data="coDriverInfo(truck.lastpoint.codriver)"
                >
                    <div class="tiny-plafond">
                        <tiny-plafond :driver="truck.lastpoint.codriver" />
                    </div>
                </cloud-panel>

                <cloud-panel
                    v-if="
                        !(truck.lastpoint.driver || truck.lastpoint.codriver) &&
                        truck.lastpoint.last_driver
                    "
                    :ignore-null-value="true"
                    :title="__('last_driver_detected')"
                    :data="{
                        driver: checkCardLastDriver,
                        card: { value: truck.lastpoint.last_driver.card },
                        data: {
                            value: moment(truck.lastpoint.last_driver_date, 'UTC').format(
                                'L HH:mm:ss'
                            )
                        }
                    }"
                >
                    <div class="tiny-plafond">
                        <tiny-plafond :driver="truck.lastpoint.last_driver" />
                    </div>
                </cloud-panel>
                <cloud-panel
                    v-if="
                        !truck.lastpoint.driver &&
                        !truck.lastpoint.codriver &&
                        !truck.lastpoint.last_driver &&
                        truck.lastpoint.rfid
                    "
                    :ignore-null-value="true"
                    :title="__('driver').toUpperCase()"
                    :icon="'<i class=\'fa-solid fa-id-badge\'></i>'"
                    :rfid="!truck.lastpoint.rfid.driver"
                    :data="rfidInfo(truck.lastpoint.rfid)"
                >
                    <div
                        v-if="
                            truck.lastpoint.rfid.driver &&
                            truck.lastpoint.rfid.driver.card_type != 112
                        "
                        class="tiny-plafond"
                    >
                        <tiny-plafond :driver="truck.lastpoint.rfid.driver" />
                    </div>
                </cloud-panel>
                <p
                    class="text-center p-5"
                    v-if="
                        !truck.lastpoint.driver &&
                        !truck.lastpoint.codriver &&
                        !truck.lastpoint.last_driver &&
                        !truck.lastpoint.rfid
                    "
                >
                    {{ __('no_driver') }}
                </p>
            </span>
        </div>
    </div>
</template>

<script>
import { toRefs } from 'vue'
import CloudPanel from './CloudPanel.vue'
import TelemetryCloudPanel from './TelemetryCloudPanel.vue'
import { useVehicleAlerts } from '@/composables/useVehicleAlerts'
import { useVehicleParameters } from '@/composables/useVehicleParameters'
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'DetailsBody',
    props: {
        truck: {
            type: Object,
            required: true
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        sharedView: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const { truck } = toRefs(props)
        const { alerts } = useVehicleAlerts(truck)
        const { commands, lock_command } = useVehicleParameters(truck)

        return {
            alerts,
            commands,
            lock_command
        }
    },
    components: {
        CloudPanel,
        TelemetryCloudPanel
    },
    data() {
        return {
            page: 'truck',

            dayFirstPoint: null,
            avarageDataTrucks: null,
            localTags: this.truck.tags || [],

            truck_lock: false,

            advancedTelemetries: [],

            statusList: []
        }
    },
    created() {
        this.getFirstPointOfToday()

        this.getAvarageDataTrucks()

        this.getDriverStatus()

        if (this.config && this.config.detail_page) {
            this.page = this.config.detail_page

            if (this.$mq != 'xs' && this.$mq != 'sm' && this.page == 'map') {
                this.page = 'truck'
            }
        }
    },
    computed: {
        vehicleTemperatureProbes() {
            let probes = this.truck.lastpoint.temperature_probes.filter((t) => t)

            if (!probes.length) return null

            return probes.reduce((c, t, i) => {
                c['temperature.probe.' + i] = {
                    value: (t / 10).toFixed(2),
                    um: '°C'
                }

                return c
            }, {})
        },
        vehicleAlertsList() {
            return Object.values(this.alerts) || []
        },
        checkCardDriver() {
            if (!this.truck.lastpoint.driver) return null

            return {
                value:
                    this.truck.lastpoint.driver.name == 'NO CARD'
                        ? this.__('no_card')
                        : this.truck.lastpoint.driver.name
                        ? this.truck.lastpoint.driver.name +
                          ' ' +
                          this.truck.lastpoint.driver.surname
                        : '- ' + this.__('no_card') + ' -'
            }
        },
        checkRfidCardDriver() {
            return {
                value: !this.truck.lastpoint?.rfid?.driver
                    ? this.__('N.A.')
                    : this.truck.lastpoint.rfid.driver.name == 'NO CARD'
                    ? this.__('no_card')
                    : this.truck.lastpoint.rfid.driver.name
                    ? this.truck.lastpoint.rfid.driver.name +
                      ' ' +
                      this.truck.lastpoint.rfid.driver.surname
                    : '- ' + this.__('no_card') + ' -'
            }
        },
        checkCardCodriver() {
            if (!this.truck.lastpoint.codriver) return null

            return {
                value:
                    this.truck.lastpoint.codriver.name == 'NO CARD'
                        ? this.__('no_card')
                        : this.truck.lastpoint.codriver.name
                        ? this.truck.lastpoint.codriver.name +
                          ' ' +
                          this.truck.lastpoint.codriver.surname
                        : '- ' + this.__('no_card') + ' -'
            }
        },
        checkCardLastDriver() {
            if (!this.truck.lastpoint.last_driver) return null

            return {
                value:
                    this.truck.lastpoint.last_driver.name == 'NO CARD'
                        ? this.__('no_card')
                        : this.truck.lastpoint.last_driver.name
                        ? this.truck.lastpoint.last_driver.name +
                          ' ' +
                          this.truck.lastpoint.last_driver.surname
                        : '- ' + this.__('no_card') + ' -'
            }
        },
        commandsCloud() {
            return {
                [this.lock_command.nome]: {
                    type: 'button_group',
                    value: this.activeCommandLock,
                    buttons: [
                        {
                            buttonLabel: this.__('turn_on'),
                            buttonClass: 'btn-success',
                            buttonRef: 'immobilizer_on',
                            value: 1
                        },
                        {
                            buttonLabel: this.__('turn_off'),
                            buttonClass: 'btn-danger',
                            buttonRef: 'immobilizer_off',
                            value: 0
                        }
                    ]
                }
            }
        },
        activeCommandLock() {
            let telemetry = (this.advancedTelemetries || []).find(
                (t) => t.value?.config?.label == this.lock_command.nome
            )

            if (!telemetry) return null

            return telemetry.value.parsed ? this.__('turned_on') : this.__('turned_off')
        },
        infoCloud() {
            if (this.sharedView || !this.$can('use', 'telemetry')) {
                return {
                    speed: {
                        value: this.truck.lastpoint.tacho_speed || this.truck.lastpoint.speed || 0,
                        um: 'Km/h'
                    },
                    altitude: {
                        value: this.truck.lastpoint.altitude,
                        um: 'm s.l.m.'
                    }
                }
            } else {
                let source = this.truck.lastpoint || {}
                let details = this.truck.details || {}
                let info = {}

                if (details.brand) {
                    info.brand = {
                        value: details.brand
                    }
                }
                if (details.model) {
                    info.model = {
                        value: details.model
                    }
                }
                if (source.tacho_speed !== null && source.tacho_speed !== undefined) {
                    info.tacho_speed = {
                        value: source.tacho_speed,
                        um: 'Km/h'
                    }

                    info.speed = {
                        value: source.speed || 0,
                        um: 'Km/h'
                    }
                } else {
                    info.speed = {
                        value: source.tacho_speed || source.speed || 0,
                        um: 'Km/h'
                    }
                }
                if (
                    source?.telemetry?.tacho_overspeed !== null &&
                    source?.telemetry?.tacho_overspeed !== undefined
                ) {
                    info.tacho_overspeed = {
                        value:
                            source?.telemetry?.tacho_overspeed == 0 ? this.__('no') : this.__('yes')
                    }
                }
                if (source.fuel_level) {
                    var value = source.fuel_level
                    var capacity = Object.values(this.truck.parameters || {}).find(
                        (p) =>
                            p.nome == 'Capienza del serbatoio (L)' ||
                            p.nome == 'Capienza del serbatoio (l)'
                    )

                    if (capacity) {
                        value = (value / 100) * capacity.valore
                    }

                    info.fuel_level = {
                        value: new Intl.NumberFormat().format(Math.trunc(parseInt(value))),
                        um: capacity ? 'l' : '%',
                        additional: capacity ? '(' + source.fuel_level + ' %)' : null
                    }
                }
                if (source.odometer) {
                    info.odometer = {
                        value: new Intl.NumberFormat().format(Math.trunc(source.odometer)),
                        um: 'Km'
                    }
                }
                if (source.gps_odometer) {
                    info.odometer_gps = {
                        value: new Intl.NumberFormat().format(Math.trunc(source.gps_odometer)),
                        um: 'Km'
                    }
                }
                if (source.engine_rpm) {
                    info.engine_rpm = {
                        value: new Intl.NumberFormat().format(Math.trunc(source.engine_rpm)) || 0,
                        um: 'rpm'
                    }
                }
                if (source.altitude) {
                    info.altitude = {
                        value: new Intl.NumberFormat().format(source.altitude) || 0,
                        um: 'm s.l.m.'
                    }
                }
                if (source.weight) {
                    info.weight = {
                        value: new Intl.NumberFormat().format(Math.trunc(source.weight)),
                        um: 'Kg'
                    }
                }

                if (this.avarageDataTrucks) {
                    if (this.avarageDataTrucks.distance) {
                        info.daily_distance = {
                            value: new Intl.NumberFormat().format(
                                this.avarageDataTrucks.distance.toFixed(0)
                            ),
                            um: 'Km'
                        }
                    }
                    if (this.avarageDataTrucks.gps_distance) {
                        info.distance_gps = {
                            value: new Intl.NumberFormat().format(
                                this.avarageDataTrucks.gps_distance.toFixed(0)
                            ),
                            um: 'Km'
                        }
                    }
                    if (this.avarageDataTrucks.fuel_consumption) {
                        info.fuel_consumption = {
                            value: new Intl.NumberFormat().format(
                                this.avarageDataTrucks.fuel_consumption.toFixed(2)
                            ),
                            um: 'l'
                        }
                    }
                    if (this.avarageDataTrucks.fuel_avg_consumption) {
                        info.fuel_avg_consumption = {
                            value: new Intl.NumberFormat().format(
                                this.avarageDataTrucks.fuel_avg_consumption.toFixed(2)
                            ),
                            um: 'Km/l'
                        }
                    }
                }

                return {
                    ...info
                }
            }
        },
        deviceCloud() {
            let deviceInfo = this.truck.lastpoint.telemetry
            return Object.keys(deviceInfo)
                .filter((k) =>
                    [
                        'device_timestamp',
                        'battery_level',
                        'gnss_status',
                        'gsm_signal',
                        'tacho_source',
                        'external_voltage'
                    ].includes(k)
                )
                .reduce((c, t) => {
                    let value = null

                    if (/.{4}-.{2}-.{2}/.test(deviceInfo[t])) {
                        value = this.moment(deviceInfo[t], 'UTC').format('L HH:mm:ss')
                    } else {
                        if (t == 'gnss_status') value = this.parseGNSSStatus(deviceInfo[t])
                        else if (t == 'battery_level') {
                            value = this.parseBatteryLevel(deviceInfo[t])
                        } else if (t == 'gsm_signal') value = this.parseGSMSignal(deviceInfo[t])
                        else if (t == 'tacho_source') value = this.parseTachoSource(deviceInfo[t])
                        else if (t == 'external_voltage')
                            value = this.parseExternalVoltage(deviceInfo[t])
                        else value = deviceInfo[t]
                    }
                    if (value !== null) c[t] = { value: value }

                    return c
                }, {})
        },
        parametersCloud() {
            return this.truck.parameters
                ? Object.values(this.truck.parameters).reduce((c, o) => {
                      if (o.nome != 'Capienza del serbatoio (l)') c[o.nome] = { value: o.valore }

                      return c
                  }, {}) // eslint-disable-line no-mixed-spaces-and-tabs
                : null
        },
        dailyReport() {
            return {
                partenza: {
                    value: this.moment(this.dayFirstPoint.date, 'UTC').format('L HH:mm')
                },
                last_point: {
                    value: this.moment(this.truck.lastpoint.date, 'UTC').format('L HH:mm')
                },
                duration: {
                    value: this.moment(this.truck.lastpoint.date).diff(
                        this.dayFirstPoint.date,
                        'seconds'
                    )
                },
                distance: {
                    value: new Intl.NumberFormat().format(
                        this.truck.lastpoint.odometer - this.dayFirstPoint.odometer
                    ),
                    um: 'km'
                },
                gps_distance: {
                    value: new Intl.NumberFormat().format(
                        this.truck.lastpoint.gps_odometer - this.dayFirstPoint.gps_odometer
                    ),
                    um: 'km'
                },
                consumption: {
                    value: this.truck.lastpoint.fuel_total - this.dayFirstPoint.fuel_total,
                    um: 'l'
                }
            }
        },
        activeTruckEvents() {
            return this.trucksEvents.filter((e) => e.truck_id == this.truck.id)
        },
        ...mapState({
            config: (state) => state.geoFleet.config,
            trucksEvents: (state) => state.geoFleet.trucksEvents,
            truck_hooked_list: (state) => state.geoFleet.truck_hooked_list
        }),
        ...mapGetters({
            getTrucksEventsSinceLastEventId: 'getTrucksEventsSinceLastEventId',
            is_truck_hooked: 'isTruckHooked',
            get_vehicle_hooked: 'getVehicleHooked',
            get_type_vehicle_hooked: 'getTypeVehicleHooked'
        })
    },
    methods: {
        driverInfo(driver) {
            if (!driver) return null

            let infoObj = {}

            if (driver) {
                infoObj = {
                    driver: this.checkCardDriver,
                    card: { value: driver.card }
                }
                if (driver.state) {
                    let color = ''
                    if (this.config?.driversStatusColors) {
                        color = this.config?.driversStatusColors[driver.state.id]
                    }
                    infoObj.driver_status = {
                        value: { ...driver.state, color }
                    }
                }
            }
            return infoObj
        },

        coDriverInfo(coDriver) {
            let infoObj = {
                driver: this.checkCardCodriver,
                card: { value: coDriver.card }
            }

            if (coDriver.state) {
                let color = ''
                if (this.config?.driversStatusColors) {
                    color = this.config?.driversStatusColors[coDriver.state.id]
                }
                infoObj.driver_status = {
                    value: { ...coDriver.state, color }
                }
            }
            return infoObj
        },

        rfidInfo(rfid) {
            let infoObj = {
                driver: this.checkRfidCardDriver,
                card: {
                    value: rfid.card_id
                },
                timeStamp: {
                    value: this.moment(parseInt(rfid.timestamp.$date.$numberLong), 'UTC').format(
                        'L HH:mm'
                    )
                },
                state: {
                    value: this.__(rfid.report_id)
                }
            }
            if (rfid.state) {
                let color = ''
                if (this.config?.driversStatusColors) {
                    color = this.config?.driversStatusColors[rfid.state.id]
                }
                infoObj.driver_status = {
                    value: { ...rfid.state, color }
                }
            }
            return infoObj
        },
        async getDriverStatus() {
            try {
                const response = await this.$fetch(this.$env.GOLIA_API + '/drivers/status')

                if (!response.ok) throw Error(response.statusText)

                let driverStatusResponse = await response.json()

                this.statusList = driverStatusResponse.data
            } catch (error) {
                console.log(error)
            }
        },
        getStatusLabel(status) {
            let statusObj = this.statusList.find((s) => s.ID == status)

            return statusObj ? statusObj.DESCRIZIONE : status
        },
        telemetriesLoaded(telemetries) {
            this.advancedTelemetries = telemetries
        },
        switchInputChange(parameter, value) {
            // STAT TURISMO
            this.truck_lock = value

            this.$fetch(this.$env.GOLIA_API + '/trucks/' + this.truck.id + '/command', {
                method: 'POST',
                body: JSON.stringify({
                    command: {
                        action: 'dout',
                        code: parameter,
                        params: [1]
                    }
                })
            })
                .then(() => {})
                .catch((err) => {
                    console.log(err)
                })
        },
        commandsClick(command) {
            // let commandData = this.commandsCloud[command.eventName]
            let notification =
                command.command +
                ' (' +
                (command.value === 1 ? this.__('turn_on') : this.__('turn_off')) +
                '): ' +
                ' ' +
                this.__('command_sent')

            let confirmation = this.__('confirm_send_command')

            this.$bvModal
                .msgBoxConfirm(confirmation, {
                    title: this.__('warning'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: this.__('yes'),
                    cancelTitle: this.__('no'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        this.sendCommand(command, notification)
                    }
                })
        },
        sendCommand(command, notification) {
            this.$fetch(this.$env.GOLIA_API + '/trucks/' + this.truck.id + '/command', {
                method: 'POST',
                body: JSON.stringify({
                    command: {
                        action: 'dout',
                        code: this.lock_command.nome,
                        params: [command.value]
                    }
                })
            })
                .then(() => {
                    this.$snotify.success(notification, { position: 'centerTop' })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        async getAvarageDataTrucks() {
            try {
                const response = await this.$fetch(
                    this.$env.GOLIA_API + '/geofleet/trucks/' + this.truck.id + '/average'
                )

                if (!response.ok) throw Error(response.statusText)

                let avarageDataTrucks = await response.json()

                this.avarageDataTrucks = avarageDataTrucks.data
            } catch (error) {
                console.log(error)
            }
        },
        getFirstPointOfToday() {
            // this.$fetch(
            // 	this.$env.GOLIA_API +
            // 		"/geofleet/trucks/" +
            // 		this.truck.id +
            // 		"/dayfirstpoint"
            // )
            // 	.then((res) => {
            // 		return res.json();
            // 	})
            // 	.then((res) => {
            // 		this.dayFirstPoint = res.data;
            // 	});
        },
        addNewTag(newTag) {
            const tag = {
                id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
                name: newTag,
                loading: false,
                error: false
            }

            this.localTags.push(tag)
            this.handleTag(tag, this.truck.id, 'add')

            this.$store.dispatch('addCompanyTag', {
                vueInstance: this,
                item: tag
            })
        },
        handleTag(option, id, action) {
            let objToSend = {}

            if (action == 'add') {
                objToSend = {
                    attach: [option.name]
                }
            } else if (action == 'remove') {
                objToSend = {
                    detach: [option.name]
                }
            }

            this.$fetch(this.$env.GOLIA_API + '/trucks/' + this.truck.id + '/tag', {
                method: 'POST',
                body: JSON.stringify(objToSend)
            })
                .then(() => {
                    this.$store.dispatch('loadCompanyTags', this)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        toggleMultiselect(isOpen, ref) {
            if (!isOpen) {
                this.$refs[ref].activate()
            }
        },
        parseGNSSStatus(value) {
            return this.__('gnss_status_values.' + value)
        },
        parseGSMSignal(value) {
            let values = {
                0: 'signal-slash',
                1: 'signal-weak',
                2: 'signal-fair',
                3: 'signal-good',
                4: 'signal-strong',
                5: 'signal'
            }

            return {
                value: value,
                icon: values[value]
            }
        },
        parseTachoSource(value) {
            let values = {
                0: this.__('none'),
                1: 'K Line',
                2: 'CAN Bus',
                3: 'CAN Bus',
                4: 'FMS'
            }

            return values[value]
        },
        parseExternalVoltage(value) {
            let voltage = new Intl.NumberFormat(
                this.$store.state?.loggedUser?.params?.Lingua || 'it-IT',
                {
                    maximumFractionDigits: 2
                }
            ).format(value / 1000)
            return voltage + ' V'
        },
        parseBatteryLevel(value) {
            return value + ' %'
        }
    },
    watch: {
        truck() {
            this.getFirstPointOfToday()

            this.getAvarageDataTrucks()

            this.localTags = this.truck.tags || []
        },
        localTags() {
            this.$emit('updateTruckTags', this.localTags)
        },
        page(value) {
            this.$store.dispatch('saveConfig', { detail_page: value, vueInstance: this })
        }
    }
}
</script>

<style scoped lang="scss">
@import './styles/DetailsBody.scss';
</style>
