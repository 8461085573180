import { render, staticRenderFns } from "./DetailsBody.vue?vue&type=template&id=cc776ff0&scoped=true"
import script from "./DetailsBody.vue?vue&type=script&lang=js"
export * from "./DetailsBody.vue?vue&type=script&lang=js"
import style0 from "./DetailsBody.vue?vue&type=style&index=0&id=cc776ff0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc776ff0",
  null
  
)

export default component.exports